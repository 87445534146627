@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital@0;1&display=swap');

html {
  scroll-behavior: smooth;
}
body { margin: 0 auto; background:#f8f8f8}

.header-banner{
  /*margin-top:90px;*/
  width: 100%;
  height: 80vh;
  position:relative;
/*  background-image: url(img/sbt.jpg);*/
  background-color: #cccccc; /* Used if the image is unavailable */
  background-position: center; /* Center the image */
  background-position-y: 60%;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.header-banner-home{
  /*margin-top:90px;*/
  width: 100%;
  height: 80vh;
  position:relative;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#myVideo{
  object-fit: cover;
  width: 100vw;
  height: 100%;
  position: absolute;
  /* top: 0; */
  bottom: 0;
  left: 0;
}
.header-banner .dark-bg{
  position: absolute;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.tool-bar-top{
  min-height:90px!important;
  display:flex;
  justify-content: space-between;
  align-items: center;
}
.top-menu-items button{
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size:18px;
}
.top-menu-items button:hover{
  color:#07a8cd;
  transition: color 150ms;
  background: none!important;
}
.tool-bar-top .inactive-lang{
  color: #B2B2B2;
}



.header-banner h1{
  z-index: 2;
  color:#fff;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 6em;
  padding-top:90px;
  text-align: center;
}

.header-banner p{
  color:#fff;
  z-index: 2;
  margin: 35px 0 75px 0;
  font-size: 1.5em;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.header-banner button{
  color:#fff;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.5em;
  background:#07a8cd;
  width:300px;
  height:68px;
  text-align: center;
  text-transform: none;
  border-radius: 10px;
}

.header-banner button:hover{
  background: #fff;
  color:#000;
}
.header-banner button:hover + svg{
  transform: translateY(7px);
  transition: transform 0.5s ease-in-out;
}
.header-banner svg{
  margin-top:18px;
  z-index:11
}


/*
OLDAL
*/
.App .header-2{
  text-align: center;
  color:#000;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 2.25em;
  margin: 0 0 35px 0;
}

.back-to-home{
  display:flex;
  align-items: center;
  cursor:pointer;
}
.back-to-home:hover span{
  color: #07a8cd;
  transform: translateX(8px);
  transition: transform 0.5s ease-in-out;
}
.back-to-home:hover svg path {
  fill:  #07a8cd;
}
.back-to-home span{
  text-transform: uppercase;
  font-size:14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color:#B2B2B2;
  margin-left:12px;
}

footer{
  background: #858588;
  display: flex;
  padding: 30px 10px;
}

.result-container{
  justify-content: center;
}
.App .card-header-2{
  color:#000;
  z-index: 2;
  margin: 30px 0 30px 0;
  font-size: 20px;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}
.card-text{
  text-align: justify;
  font-family: 'Open Sans', sans-serif;
  font-size:14px;
  color:#7a7a7a;
  height:75px;
}
.card-info{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.MuiCardActions-spacing button{
  margin-top:15px;
  margin-left:0!important;
  width: 170px;
  height:50px;
  border-radius: 10px;
  border: 2px solid #07a8cd;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
  margin-bottom:16px
}
.MuiCardActions-spacing button:hover{
  background: #07a8cd;
  color:#fff;
}
.card-info-item{
  display:flex;
  align-items: center;
  font-size: 14px;
  padding:12px 0;
}
.card-info-item svg{
  margin-right: 5px;
}
.marker-info{
  position:absolute;
  right: 70px;
  top:70px;
  background: #fff;
  z-index:10;
  padding: 20px;
}
.marker-info img{
  width: 170px;
}
.marker-info h6{
  text-align: center;
  margin: 10px 0;
  font-size: 14px;
}
.marker-info span{
  font-size: 14px;
}
.marker-info button{
  margin-top: 10px;
}
.marker-info svg{
  position: absolute;
  right:0;
  top:0;
  cursor: pointer;
}
.route-info{
  display:flex;
}
.route-info .route-description{
  flex: 1;
}
.route-info .route-info-meta{
  flex:1;
  margin:10px 0 0 160px;
}
.route-info .card-info-item{
  padding: 10px;
}
.marker-list-item{
  display:flex;
  width:100%;
  justify-content: center;
  margin:48px 0;
  align-items: center;
  flex-direction: column;
}
.next-back button{
  width: 245px;
  height:50px;
  margin: 0 22px;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #07a8cd;
  font-family: 'Poppins', sans-serif;
}
.next-back button svg{
  margin: 0 5px;
}
.marker-list-item img{
  width:350px;
  height:230px;
  border-radius: 10px;
}
.marker-list-item > div{
  min-width: 270px;
  margin: 0 20px;
}
.marker-list-item h6{
  font-weight:bold;
  margin: 20px 0 10px 0;
  text-align: center;
}
.marker-list-item button{
  border:none;
  font-weight: 600;
  font-size: 14px;
  color: #07a8cd;
  font-family: 'Poppins', sans-serif;
}
.marker-list-item button:hover{
  color:#000;
}
.marker-media img, .marker-media video,  .marker-media audio{
  display:block;
  margin: 20px auto;
  max-width: 100%;
 /* flex: 0 50%;*/
}
.marker-media{
  display:flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.marker-media img{
  border-radius: 10px;
}
.marker-media img, .marker-media video, .marker-media audio{
  width: 556px;
  height: 370px;
}
.marker-media p{
  max-width: 556px;
}
@media only screen and (max-width: 1366px) {
  .header-banner h1 {
    font-size: 4rem;
    text-align: center;
  }
  .header-banner p{
    font-size: 20px;
  }
}
@media only screen and (max-width: 875px) {
  .App .header-2{
    text-align: center!important;
    font-size: 22px;
  }
  .marker-media img, .marker-media video, .marker-media audio{
    width:calc(100% - 40px);
    height:260px;
  }
  .marker-media p{
    max-width: calc(100% - 40px);
    margin-left: auto;
    margin-right: auto;
  }
  .header-banner h1, .header-banner h2 {
    font-size: 2rem;
    text-align: center;
  }
  .search-container > div {
    width: 100% !important;
    margin: 20px!important;
  }
  .route-info{
    flex-direction: column;

  }
  .back-to-home{
    padding:20px;
  }
  .route-info .route-info-meta{
    display:flex;
    margin-left: 0px;
    flex-direction: column;
    align-items: flex-start;
  }
  .route-info .card-info-item{
    /*flex-direction: column;*/
    align-items: center;
    padding:20px;
  }
  .MuiGrid-spacing-xs-3{
    width: 100%!important;
    margin: 0!important;
  }
  .route-info .card-info-item span{
    text-align: center;
  }
  .route-description{
    padding: 0 20px;
    text-align: justify;
  }
  .marker-list-item{
    flex-direction: column;
  }
  .marker-list-item > div{
    margin: 10px 0;
    width: 100%;
    text-align: center;
  }
  .marker-media{
    flex-direction: column;
    align-items: center;
  }

}

@media (min-width: 600px){
.MuiToolbar-gutters {
  padding-left: 90px!important;
  padding-right: 90px!important;
}
}
.MuiAppBar-root:not(.top-bar){
  position: fixed!important;
  bottom:0;
  left: 0;
  width: 100%;
}
html, body , #root{
  max-width: 100%;
  overflow-x: hidden;
}
iframe{
  max-width: 100%;
}


.header-banner::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

.header-banner::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

#myVideo::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}
#myVideo {
  pointer-events: none;
}
